import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="enquiries"
export default class extends Controller {
  connect() {
    $(".EnquiryForm").validate({
      rules: {
        "inquiry[name]": {
          required: true,
        },
        "inquiry[email]": {
          required: true,
        },
        "inquiry[message]": {
          required: true,
        }
      },
      messages: {
        "inquiry[name]": {
          required: "Name is required.",
        },
        "inquiry[email]": {
          required: 'You must provide your email.',
        },
        "inquiry[message]": {
          required: 'Comment or Message is required.',
        }
      }
    });

    $(".EnquiryFormMessage").validate({
      rules: {
        "inquiry[name]": {
          required: true,
        },
        "inquiry[email]": {
          required: true,
        }
      },
      messages: {
        "inquiry[name]": {
          required: "Name is required.",
        },
        "inquiry[email]": {
          required: 'You must provide your email.',
        }
      }
    });
  }
}
