import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user"
export default class extends Controller {
  connect() {
    $.validator.addMethod("noSpecialChars", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9]+$/.test(value);
    }, "Your username contains invalid characters.");

    $(".UsersForm").validate({
      rules: {
        "user[username]": {
          required: true,
          minlength: 3,
          noSpecialChars: true
        },
        "user[email]": {
          required: true,
        },
        "user[password]": {
          required: true,
        },
        "user[password_confirmation]": {
          required: true,
        },
      },
      messages: {
        "user[username]": {
          required: "Username is required.",
          minlength: 'Your Username must contain at least 3 characters'
        },
        "user[email]": {
          required: 'You must provide your email.',
        },
        "user[password]": {
          required: 'Password is required.',
        },
        "user[password_confirmation]": {
          required: 'Confirm password is required.',
        },
      }
    });

    $(".UsersLogInForm").validate({
      rules: {
        "user[login]": {
          required: true,
        },
        "user[password]": {
          required: true,
        }
      },
      messages: {
        "user[login]": {
          required: "Please enter your username or email.",
        },
        "user[password]": {
          required: 'Please enter your password.',
        }
      }
    });

     $(".ForgotPasswordForm").validate({
      rules: {
        "user[email]": {
          required: true,
        }
      },
      messages: {
        "user[email]": {
          required: "Please enter your email.",
        }
      }
    });
  }
}
